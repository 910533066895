import {
    GrowthBook,
    Experiment,
    Result,
} from '@growthbook/growthbook';
import { track } from './Analytics';
import EventNames from 'src/analytics/EventNames';
import { env } from 'src/env.mjs';

const onExperimentViewed = (
    experiment: Experiment<string>,
    result: Result<string>,
) => {
    const experimentId = experiment.key;
    const variationId = result.key;

    track(EventNames.EXPERIMENT_VIEWED, {
        experimentId,
        variationId,
    });
};

export const growthbook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.NODE_ENV === 'development',
    trackingCallback: onExperimentViewed,
});
