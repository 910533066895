import { env } from 'src/env.mjs';
import { Brand, EventProps } from './EventProps';

import { AnalyticsBrowser } from '@segment/analytics-next';

const analytics = AnalyticsBrowser.load(
    {
        writeKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        cdnURL: env.NEXT_PUBLIC_SEGMENT_CDN_URL,
    },
    {
        integrations: {
            'Segment.io': {
                apiHost: env.NEXT_PUBLIC_SEGMENT_API_HOST,
            },
        },
    },
);

export const identify = (
    userId: string,
    traits?: { [key: string]: string },
) => {
    analytics.identify(userId, {
        brand: Brand.MOSH,
        ...traits,
    });
};

export const page = (name: string, category = '', properties?: EventProps) => {
    analytics.page(category, name, properties);
};

export const track = (eventName: string, properties?: EventProps) => {
    analytics.track(eventName, {
        brand: Brand.MOSH,
        url: window.location.href,
        path: window.location.pathname,
        ...properties,
    });
};

export const setAnonymousId = (anonymousId: string) => {
    analytics.setAnonymousId(anonymousId);
};
